.View {
	display: flex;
	flex-direction: column;
	.first_View {
		display: flex;
		gap: 25px;
	}
}

.subHeaders {
	display: flex;
	justify-content: space-between !important;
	.subheaderButton {
		display: flex;
		gap: 15px;
	}
	.subheaderDrop {
		display: none;
	}
}

@media only screen and (max-width: 1199px) {
	.View {
		.first_View {
			flex-direction: column;
			gap: 0;
		}
	}
}

@media only screen and (max-width: 1199px) {
	.subHeaders {
		.subheaderDrop {
			display: block;
		}
		.subheaderButton {
			display: none;
		}
	}
}
.checkEditModal {
	display: flex;
	flex-direction: column;
	gap: 25px;
	padding-top: 40px;
	.checkToggleInEditModal {
		display: flex;
		flex-direction: column;
		gap: 25px;
	}
}
