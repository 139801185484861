.errorImg{
    width: auto;
    height: 30vmax;
}

.textOne{
    font-size: 40px;
    font-weight: bold;
}

.textThree{
    font-size: 20px;
    padding-top: 10px;
}