.questionAndShortCode {
	display: flex;
	gap: 15px;

	.questionAndShortCodeInput {
		display: flex;
		width: 100%;
		gap: 15px;
		.form-floating {
			width: 50%;
		}
	}
	.questionAndShortCodeLength {
		display: flex;
		gap: 15px;
		align-items: center;
		.toggle {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4px;
			.form-switch {
				input {
					padding: 8px 16px;
				}
			}
			// gap: 4px;
		}
	}
}

@media only screen and (max-width: 992px) {
	.questionAndShortCode {
		flex-direction: column;
		.questionAndShortCodeInput {
			flex-direction: column;
			.form-floating {
				width: 100%;
			}
		}
		.questionAndShortCodeLength {
			width: 100%;
			.form-floating {
				width: 50%;
			}
		}
		button {
			width: 100px;
			padding: 10px !important;
		}
	}
}

@media only screen and (max-width: 576px) {
	.questionAndShortCode {
		.questionAndShortCodeLength {
			flex-direction: column;
			.form-floating {
				width: 100%;
			}
		}
	}
}
