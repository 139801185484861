.txtAvatar {
	color: #fff;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: bold;
	-ms-flex-align: center;
	align-items: center;
	width: 260px;
	height: 260px;
	letter-spacing: 2px;
	font-size: 5rem;
	border-radius: 100%;
	span {
		text-transform: uppercase;
	}
}
