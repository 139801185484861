.pricingCardDivMain {
	display: flex;
	gap: 25px;
	justify-content: center;
	flex-wrap: wrap;

	.pricingCardDiv {
		width: calc(32% - 25px);
		// width: 415px !important;
	}
}
.toogleDiv {
	width: 500px !important;
}
@media only screen and (max-width: 1149px) {
	.pricingCardDiv {
		width: calc(50% - 25px) !important;
	}
}

.prcingSuccessMain {
	display: flex;
	align-items: center;
	justify-content: center;
	.prcingSuccessLogo {
		width: 400px;

		img {
			width: 500px;
		}
	}
	.prcingSuccessTxt {
		display: flex;
		flex-direction: column;
		gap: 20px;
		a {
			margin: auto;
		}
	}
}

@media only screen and (max-width: 767px) {
	.prcingSuccessLogo {
		display: none;
	}
}
@media only screen and (max-width: 659px) {
	.pricingCardDiv {
		width: 100% !important;
	}
}
@media only screen and (max-width: 599px) {
	.toogleDiv {
		width: 100% !important;
	}
}
