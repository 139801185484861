//
// Input Group
//

// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
	@include dark-mode {
		background-color: $dark-input-group-addon-bg;
		border-color: $dark-input-group-addon-border-color;
		color: $dark-input-group-addon-color;
	}
}

.searchInput {
	width: 50%;
	display: flex;
	align-items: center;
	gap: 10px;
}
@media only screen and (max-width: 767px) {
	.searchInput {
		width: 100%;
	}
}
