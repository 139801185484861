// NoReportPage
.NoReportPage {
	display: flex;
	flex-direction: column;
	gap: 25px;
	height: 100%;
	.NoReportPageHeadingDiv {
		h1 {
			text-align: center;
			font-weight: 700;
			font-size: calc(1.5rem + 1.5vw);
		}
	}
	.NoReportPageImgDiv {
		display: flex;
		justify-content: center;
		width: 100%;
		img {
			height: 50vh;
		}
	}
	.NoReportPageButtonDiv {
		display: flex;
		justify-content: center;
	}
}

.reportDetail {
	.reportDetailSheet {
		.reportDetailhead {
			font-size: calc(1.5rem + 1.5vw);
			font-weight: 700;
			font-style: italic;
			text-align: center;
			text-transform: capitalize;
			margin: auto;
		}
	}
	.reportDetailheadContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.downloadIcon {
		margin-right: 10px;
	}
	.reportMain {
		div {
			ul {
				li {
					padding: 6px 0;
				}
			}
			h1 {
				padding: 8px 0;
			}
			h2 {
				padding: 12px 0;
			}
			h3 {
				padding: 12px 0;
			}
			table {
				margin: 8px 0;
				tbody {
					tr {
						th {
							padding: 8px;
						}
						td {
							padding: 8px;
						}
					}
				}
			}
		}
	}
	.Nojanerated {
		position: relative;
		img {
			width: 100%;
			height: 700px;
		}
		h1 {
			width: 100%;
			text-align: center;
			position: absolute;
			top: 10px;
			font-weight: 700;
			font-size: 36px;
		}
	}
}

.ToManyRequestMain {
	display: flex;
	flex-direction: column;
	gap: 25px;
	align-items: center;
	.ToManyRequestTxt {
		text-align: center;
	}
}
