.table-idea {
	overflow: hidden;
	cursor: pointer;
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
	.dropdownCustom {
		ul {
			position: fixed !important;
		}
	}
}

.noRecContainer {
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.noRecText {
	font-size: 22px;
}

.table-Title {
	width: 30%;
	cursor: pointer;
	overflow: hidden;
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		white-space: pre-wrap;
	}
}

.table-drop {
	.dropdownCustom {
		ul {
			position: fixed !important;
		}
	}
}
