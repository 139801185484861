/* Mega Menu container */
.mega-menu {
	position: relative;
	display: inline-block;
	font-family: Poppins, sans-serif;
	width: 100%;
	height: 100%;

	/* Mega Menu toggle button */
	.mega-menu-toggle {
		padding-top: 0.625rem;
		padding-bottom: 0.625rem;
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		background-color: #f8f9fa;
		color: #000;
		font-size: 13px;
		border-radius: 15px;
		font-size: 1.25rem;
		font-weight: normal;
		display: flex;
		justify-content: space-between;
		align-items: center;
		-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
		-webkit-box-shadow: var(--bs-box-shadow-inset);
		box-shadow: var(--bs-box-shadow-inset);

		.activeColor,
		.unactiveColor {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		.activeColor {
			color: #000;
		}
		.unactiveColor {
			color: #7e7e7f;
		}

		.icon {
			.svg-icon--material {
				font-size: 12px;
				color: #000;
				transition: all 0.1s ease-in-out 0s;
			}
		}
	}
	.mega-menu-toggle-focus {
		border: 1px solid #b6aee9 !important;
		outline: 0 !important;
		-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
			0 0 0 0.25rem rgba(108, 93, 211, 0.25) !important;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(108, 93, 211, 0.25) !important;
		-webkit-box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(108, 93, 211, 0.25) !important;
		box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(108, 93, 211, 0.25) !important;
	}
	/* Mega Menu content container */
	.mega-menu-reuse {
		display: flex;
		position: absolute;
		top: 100%;
		left: 0;
		border-radius: 15px;
		border: 1px solid silver;
		background-color: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		z-index: 1;
		width: 100%;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		margin-bottom: 20px;
		max-height: 300px;
		overflow-y: auto;
		.menu-category {
			// max-width: 150px;
			white-space: nowrap;
			overflow: hidden;
			width: 33.333%;
			text-overflow: ellipsis;
			padding: 10px 20px;
			b {
				display: block;
				color: #6610f2;
				font-size: 14px;
				padding: 8px;
			}
			.menu-subcategory {
				border-radius: 9px;
				cursor: pointer;
				font-size: 14px;
				padding: 8px;
				text-overflow: ellipsis;
				white-space: break-spaces;
			}
			.menu-subcategory:hover {
				background-color: #6f42c1;
				color: #fff;
			}
		}
	}
	.mega-menu-reuse::-webkit-scrollbar {
		width: 12px;
	}

	.mega-menu-reuse::-webkit-scrollbar-thumb {
		background-color: #888;
		border-radius: 10px;
		border: 3px solid #fff;
	}

	.mega-menu-reuse::-webkit-scrollbar-thumb:hover {
		background-color: #555;
	}

	.mega-menu-content {
		flex-direction: column;
	}
	.mega-menu-content-two {
		flex-wrap: wrap;
		justify-content: flex-start;
		// justify-content: space-around;
	}
}

@media only screen and (max-width: 1024px) {
	.mega-menu {
		.mega-menu-reuse {
			.menu-category {
				width: 50%;
			}
		}
	}
}

@media only screen and (max-width: 425px) {
	.mega-menu {
		.mega-menu-reuse {
			.menu-category {
				width: 100%;
			}
		}
	}
}



