.copyButtonContainer {
	position: absolute;
	right: 0;
	top: 0;
	background-color: #f8f8fa;
	height: 47px;
	width: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.copyButton {
	font-size: 13px;
}

.copyButtonContainer:hover,
.copyButton:hover {
	cursor: pointer;
	color: #b3b3b3;
}
