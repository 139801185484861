.inputUpdateReport {
	display: flex;
	// align-items: end;
	border: 1px solid;
	color: #ccc;
	border-radius: 10px;
	textarea {
		border: none !important;
		overflow: auto !important;
		outline: none !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background-color: #ffff !important;
	}
	.lengthShowContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.fileIcon {
			color: #969292;
			cursor: pointer;
			font-size: 24px;
		}
		.fileIcon:hover {
			color: #b3b3b3;
		}
		.lengthShow {
			span {
				display: flex;
				// flex-direction: column;
				width: 100px;
				align-items: center;
				span {
					font-size: 10px;
					font-weight: 500;
					color: #000;
				}
			}
		}
		.file {
			display: flex;
			align-items: center;
			flex-direction: column;
			overflow: hidden;
			span {
				color: #000;
				font-size: 11px;
				display: inline-block;
				width: 70px;
				white-space: nowrap;
				overflow: hidden !important;
				text-overflow: ellipsis;
			}
		}
	}
}

.inputUpdateReportTwo {
	border-color: #b6aee9 !important;
	outline: 0 !important;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(108, 93, 211, 0.25) !important;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(108, 93, 211, 0.25) !important;
}
// textarea {
// 	resize: none !important;
// }
