.ReportIdeaHeaderMain {
	background-color: #e2dff6;
	border-radius: 10px;
	padding: 30px;
	margin-bottom: 30px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	.ReportIdeaHeaderMainHeadingFeedback {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}
	h2 {
		width: 100%;
		text-align: end;
		font-weight: 700;
		text-decoration: underline;
	}
	.rating_div {
		width: calc(70% - 20px);
		.star_rating {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}
		h6{
			color: rgb(105, 54, 245);
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	ol {
		margin-top: 15px;
		li {
			font-size: 15px;
		}
	}
}


@media only screen and (max-width: 767px) {
	.ReportIdeaHeaderMain {
		.ReportIdeaHeaderMainHeadingFeedback{
			flex-direction: column-reverse;
		}
		h2{
			width: auto;
		}
		.rating_div {
			width: auto;
		}
	}
  }