.dashboardPageChart {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 25px;
	.radialbar {
		width: 50%;
	}
}
.cardcontainers {
	display: flex;
	justify-content: space-around;
	gap: 25px;
	.card {
		width: calc(50% - 25px) !important;
	}
}

@media only screen and (max-width: 576px) {
	.cardcontainers {
		flex-direction: column;
		.card {
			width: 100%!important;
		}
	}
}
@media only screen and (max-width: 768px) {
	.dashboardPageChart {
		flex-direction: column;
		gap: 10px;
		.radialbar {
			width: 100%;
		}
	}
}
