.pageWrappers {
	// background-color: #d3d3d3;
	.unSubscribed {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 200px;
			border-radius: 5px;
		}
	}
	.success-message {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 200px;
			border-radius: 5px;
		}
	}
}

